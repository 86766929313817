function setEqualHeight() {
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".product-single-countdown-031 .purchase_container").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    $.fn.matchHeight._update();
});

$(window).on("load", function() {
    var current_breakpoint_num = getNumSkelSize();
    if (current_breakpoint_num > 2) {
        $("#prva-slikovni-ob-artiklih .grid-element-content-wrapper").matchHeight({
            target : $("#prva-artikli-ob-slikovnem")
        });
    } else {
        $("#prva-slikovni-ob-artiklih .grid-element-content-wrapper").matchHeight({remove : true});
    }
});
$(window).on("resize", function() {
    var current_breakpoint_num = getNumSkelSize();
    if (current_breakpoint_num > 2) {
        $("#prva-slikovni-ob-artiklih .grid-element-content-wrapper").matchHeight({
            target : $("#prva-artikli-ob-slikovnem")
        });
    } else {
        $("#prva-slikovni-ob-artiklih .grid-element-content-wrapper").matchHeight({remove : true});
    }
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).on('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        viewport = $(window).width(),
        scroll_limit_1 = 150,
        logo_height,
        logo_padding,
        initial_height = 150,
        initial_padding = 25,
        target_height = 50,
        target_padding = 5;
    switch (current_breakpoint) {
        case "xlarge":
            scroll_limit_1 = 150;
            initial_height = 150;
            initial_padding = 25;
            target_height = 50;
            target_padding = 5;
            break;
        case "large":
            scroll_limit_1 = 120;
            initial_height = 120;
            initial_padding = 20;
            target_height = 50;
            target_padding = 5;
            break;
        case "medium":
            scroll_limit_1 = 50;
            initial_height = 50;
            initial_padding = 5;
            target_height = 50;
            target_padding = 5;
            break;
        case "small":
            scroll_limit_1 = 50;
            initial_height = 50;
            initial_padding = 5;
            target_height = 50;
            target_padding = 5;
            break;
        case "xsmall":
            scroll_limit_1 = 50;
            initial_height = 50;
            initial_padding = 5;
            target_height = 50;
            target_padding = 5;
            break;
        default:
            scroll_limit_1 = 150;
            initial_height = 150;
            initial_padding = 25;
            target_height = 50;
            target_padding = 5;
            break;
    }
    /* Izračunljive vrednosti */
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            logo_height = initial_height;
            logo_padding = initial_padding;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            logo_height = initial_height - (initial_height - target_height) * $(window).scrollTop() / scroll_limit_1;
            logo_padding = initial_padding - (initial_padding - target_padding) * $(window).scrollTop() / scroll_limit_1;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            logo_height = target_height;
            logo_padding = target_padding;
        }
        $("#logo a").css("padding-top", logo_padding + "px");
        $("#logo a").css("padding-bottom", logo_padding + "px");
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
    }
});
